import React, { useState } from 'react';
import { useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { v4 as uuidv4, v4 } from 'uuid';

import { Loader, Checkbox } from '@bcmi-labs/react-components';

import { triggerDownload } from '../../utils/download';
import DonateWithStripe from './donateWithStripe';
import DonateWithPaypal from './donateWithPaypal';

import './donateConfirm.scss';

const stripeTestPromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

const DonateConfirm = ({ location, title, children }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { amount, downloadUrl } = location.state;
  const [totalAmount, setTotalAmount] = useState(amount);

  const onDonationSuccess = method => {
    if (downloadUrl) {
      triggerDownload(downloadUrl);
    }

    // Send data to google analytics about donation
    const kind = downloadUrl ? 'donation-for-download' : 'donation';

    window.dataLayer.push({
      event: 'pay-for-donation',
      transactionId: uuidv4(),
      transactionAffiliation: 'Arduino Donation',
      transactionTotal: amount,
      transactionProducts: [
        {
          sku: kind + '-' + method,
          name: kind + ' via ' + method,
          category: 'donation',
          price: amount,
          quantity: 1,
        },
      ],
    });
    window.dataLayer.push({});

    navigate('/en/donate/thanks', { state: { downloadUrl: downloadUrl } });
  };
  const onDonationError = err => {
    setError(err);
  };

  const toggleFees = e => {
    setTotalAmount(e.target.checked ? (amount * 1.03 + 0.3).toFixed(2) : amount);
  };

  if (error) {
    return <h2>Something went wrong, please retry later</h2>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="donate-confirm">
      <h2>{title}</h2>
      <Helmet>
        <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_TOKEN}`}></script>
      </Helmet>
      <div className="donate-recap">
        <div className="donate-description">One-time Donation</div>
        <div className="donate-amount">${totalAmount}</div>
      </div>
      <Checkbox
        name="fees"
        value="fees"
        label="Cover the transaction fees of 3% + $0.30, so all of my donation goes to Arduino."
        onChange={toggleFees}
      />
      <div className="payment-buttons">
        <DonateWithPaypal
          amount={totalAmount}
          onDonationSuccess={onDonationSuccess}
          onDonationError={onDonationError}
          onAuthorize={setLoading}
        />
      </div>
      <div class="separator">or continue with Credit Card</div>
      <Elements stripe={stripeTestPromise} locale={i18n.language || 'en'}>
        <DonateWithStripe
          amount={totalAmount}
          onDonationSuccess={onDonationSuccess}
          onDonationError={onDonationError}
        />
      </Elements>
    </div>
  );
};

export default DonateConfirm;
