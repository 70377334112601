import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Button, Input, Loader, Select, Checkbox } from '@bcmi-labs/react-components';
import ErrorMessage from './errorMessage';

import './donateWithStripe.scss';

const API_URL = 'https://api2.arduino.cc';

const DonateWithStripe = ({ amount, onDonationSuccess, onDonationError }) => {
  const { t } = useTranslation();

  const [countries, setCountries] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/countries/v1`)
      .then(response => response.json())
      .then(data => setCountries(data));
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const handleFormSubmit = async (values, actions) => {
    try {
      const { source, error } = await stripe.createSource(elements.getElement(CardElement), {
        amount: amount * 100,
        currency: 'USD',
        owner: {
          name: values.name,
          address: {
            country: values.country,
          },
          email: values.email,
        },
        type: 'card',
      });
      if (error) {
        console.log(error);
        actions.setFieldError('stripe', error.message);
        return;
      }

      const recaptchaToken = await window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_TOKEN, { action: 'donate' });
      const result = await fetch(`${process.env.GATSBY_DONATION_API_URL}/v1/charge/withStripe`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: source.id,
          recaptcha_token: recaptchaToken,
          amount: amount * 100, // stripe wants cents
          currency: 'USD',
          donator: source.owner.name,
          email: source.owner.email,
        }),
      });

      if (!result.ok) {
        if (result.status === 400) {
          actions.setFieldError('stripe', 'The payment was declined: please check your card details');
          return;
        }
        throw new Error('donation failed');
      }
      onDonationSuccess('stripe');
    } catch (err) {
      onDonationError(t('donate:unexpectedError'));
      console.error(err);
    }
  };

  if (!countries) {
    return <Loader />;
  }

  return (
    <Formik
      validate={validateForm}
      initialValues={{ email: '', name: '', country: '', privacy: '' }}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValidating, isSubmitting, isValid }) => (
        <Form>
          <div className="donate-with-stripe">
            <div className="form-inputs">
              <div className="form-input">
                <Input
                  label="E-mail"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  error={touched?.email && errors?.email}
                />
              </div>
              <div className="form-input">
                <CardElement />{' '}
              </div>
              {errors['stripe'] ? <ErrorMessage>{errors['stripe']}</ErrorMessage> : null}
              <div className="form-input">
                <Input
                  label="Name on card"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="name"
                  error={touched?.name && errors?.name}
                />
              </div>
              <div className="form-input">
                <Select
                  label="Country"
                  options={countries.map(c => ({ label: c.name, value: c.code }))}
                  name="country"
                  onChange={value => handleChange({ target: { name: 'country', value } })}
                  onBlur={handleBlur}
                  error={touched?.country && errors?.country}
                />
              </div>
            </div>
            <div className="form-input privacy-checkbox">
              <Checkbox
                name="privacy"
                value="privacy"
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                label="I have read the [Privacy Policy](https://www.arduino.cc/PrivacyPolicy/en/) and to accept the [Terms of Service](https://www.arduino.cc/en/Main/TermsOfService)"
              />
              {touched?.privacy && errors?.privacy ? <ErrorMessage>{errors?.privacy}</ErrorMessage> : null}
            </div>
            <div className="buttons">
              <Button onPress={handleSubmit} loading={isValidating || isSubmitting}>
                Donate
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateForm = values => {
  const errors = {};

  if (!values?.email) {
    errors.email = 'The e-mail address is required';
  }

  if (!errors.email && !validateEmail(values?.email)) {
    errors.email = 'This e-mail address is not valid';
  }

  if (!values?.name) {
    errors.name = 'The name is required';
  }

  if (!values?.country) {
    errors.country = 'The country is required';
  }

  if (!values?.privacy) {
    errors.privacy = 'You have to accept this field to move forward';
  }

  return errors;
};

export default DonateWithStripe;
