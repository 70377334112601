import React, { useState } from 'react';
import { Router, useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import Donate from '../../../components/donate/donate';
import DonateConfirm from '../../../components/donate/donateConfirm';
import DonateThankYou from '../../../components/donate/donateThankYou';
import Subscription from '../newsletter/subscription';
import LearnMore from '../newsletter/learn-more';
import NewsletterThankYou from '../newsletter/thank-you';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import './style.scss';
import DownloadCount from '../../../components/donate/downloadCount';

const DonateRoute = ({ downloadUrl, title, ...props }) => {
  if (!downloadUrl) {
    return (
      <Donate title={title} downloadUrl={downloadUrl} {...props}>
        If you like Arduino, show your support with a donation.
        <br />
        Every little bit matters.
      </Donate>
    );
  }
  return (
    <Donate title={title} downloadUrl={downloadUrl} {...props}>
      Since the 1.x release in March 2015, the Arduino IDE has been downloaded <DownloadCount /> times —
      impressive! Help its development with a donation.
    </Donate>
  );
};

const DonatePage = () => {
  const location = useLocation();
  const { downloadUrl } = (location || {}).state || {};

  const title = downloadUrl ? "Download Arduino IDE & support its progress" : 'Donate to Arduino';
  const [newsletterInfo, setNewsletterInfo] = useState({});

  return (
    <Layout currentLocale="en">
      <Helmet
        bodyAttributes={{
          class: 'donate-page-body',
        }}
      />
      <SEO title={title} />
      <main className="donate-page page">
        <section>
          <Router basepath="/en/donate" className="page-content">
            <DonateRoute path="/" downloadUrl={downloadUrl} title={title} />
            <Subscription path="/newsletter" downloadUrl={downloadUrl} setNewsletterInfo={setNewsletterInfo} />
            <LearnMore path="/learn-more" newsletterInfo={newsletterInfo} />
            <NewsletterThankYou path="/newsletter-thank-you" />
            <DonateConfirm path="/confirm" title={title} />
            <DonateThankYou path="/thanks" />
          </Router>
        </section>
      </main>
    </Layout>
  );
};

export default DonatePage;
