import React from 'react';
import NewsletterSubscription from '../../../assets/NewsletterSubscription.svg';
import { Button } from '@bcmi-labs/react-components';
import { Link } from 'gatsby';
const ThankYou = () => {
  return (
    <div className="newsletter-thank-you">
      <img src={NewsletterSubscription} alt="Thank you" />
      <h2>Thank you.</h2>
      <p>Your support and love are much appreciated by the Arduino team. We can’t wait to let you know what’s next!</p>
      <p>
        Pssst! Are you looking for an intuitive and comprehensive IoT platform? Quickly bring your project to life with
        Arduino Cloud.
      </p>
      <Link to="https://cloud.arduino.cc/" target="_blank">
        <Button variant="primary">GET STARTED FOR FREE</Button>
      </Link>
    </div>
  );
};

export default ThankYou;
