import './style.scss';
import React, { useState } from 'react';
import { Button } from '@bcmi-labs/react-components';
import { navigate } from '@reach/router';
import CheckboxCard from '../../../components/checkboxCard';
import NewsletterEducation from '../../../assets/newsletter-education.jpg';
import NewsletterProfessional from '../../../assets/newsletter-professional.jpg';

const LearnMore = ({ newsletterInfo }) => {
  const [tags, setTags] = useState([]);

  const onChangeTag = (e, tag) => {
    let currentTags = new Set(tags);

    if (e.target.checked) {
      currentTags.add(tag);
    } else {
      currentTags.delete(tag);
    }

    setTags([...currentTags]);
  };

  const handleSubmit = () => {
    const data = { email: newsletterInfo.email, tags };
    fetch(`${process.env.GATSBY_API_URL}/consent/v1/subscription/update/${newsletterInfo.id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(() => {
      // navigate to thank yuo page or survey (random every 4)
      if (Math.random() < 0.25) {
        navigate('/en/newsletter/survey', { state: { id: newsletterInfo.id, email: newsletterInfo.email, tags } });
      } else {
        navigate('/en/donate/newsletter-thank-you');
      }
    });
  };

  return (
    <div className="newsletter-learn-more">
      <p className="newsletter-learn-more__thank-you">Thank you for subscribing! Your download has started</p>
      <h3>Curious to learn more?</h3>
      <p>
        Are you also a <b>teacher</b>, <b>student</b>, or <b>professional</b> that loves using Arduino in your
        day-to-day activities? Then keep up-to-date with either our <b>Education</b> or{' '}
        <b>Professional monthly newsletters</b>.
      </p>
      <p className="newsletter-learn-more__subscription-alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M13 25.0002C12.8684 25.0009 12.7379 24.9757 12.6161 24.926C12.4943 24.8762 12.3834 24.8029 12.29 24.7102L5.29 17.7102C5.1017 17.5219 4.99591 17.2665 4.99591 17.0002C4.99591 16.7339 5.1017 16.4785 5.29 16.2902C5.47831 16.1019 5.7337 15.9961 6 15.9961C6.2663 15.9961 6.5217 16.1019 6.71 16.2902L13 22.5902L26.29 9.29018C26.4783 9.10188 26.7337 8.99609 27 8.99609C27.2663 8.99609 27.5217 9.10188 27.71 9.29018C27.8983 9.47849 28.0041 9.73388 28.0041 10.0002C28.0041 10.2665 27.8983 10.5219 27.71 10.7102L13.71 24.7102C13.6166 24.8029 13.5057 24.8762 13.3839 24.926C13.2621 24.9757 13.1316 25.0009 13 25.0002Z"
            fill="#1DA086"
          />
        </svg>
        <b>Arduino weekly newsletter</b> (already subscribed)
      </p>
      <CheckboxCard
        isSelected={tags.includes('marketing_educational')}
        onChange={e => onChangeTag(e, 'marketing_educational')}
        name="marketing_educational"
        label="Education"
        description="Educators can benefit from the ever growing tech that shapes our environment through fun cool projects."
        image={NewsletterEducation}
      />
      <CheckboxCard
        isSelected={tags.includes('marketing_professional')}
        onChange={e => onChangeTag(e, 'marketing_professional')}
        name="marketing_professional"
        label="Professional"
        description="Why not awe your boss with highly innovative ways to help keep your enterprise connected at no extra cost? "
        image={NewsletterProfessional}
      />
      <div className="newsletter-learn-more__button-container">
        <Button variant="primary" onPress={handleSubmit}>
          YES, CONFIRM!
        </Button>
      </div>
    </div>
  );
};

export default LearnMore;
