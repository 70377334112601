import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PayPalButton } from 'react-paypal-button-v2';

const DonateWithPaypal = ({ onDonationSuccess, onDonationError, onAuthorize, amount }) => {
  const { t } = useTranslation();

  const charge = orderID => {
    window.grecaptcha
      .execute(process.env.GATSBY_RECAPTCHA_TOKEN, { action: 'donate' })
      .then(recaptchaToken => {
        return fetch(`${process.env.GATSBY_DONATION_API_URL}/v1/charge/withPaypal`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: orderID,
            recaptcha_token: recaptchaToken,
          }),
        });
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('request failed');
        }
        onDonationSuccess('paypal');
      })
      .catch(err => {
        console.error(err);
        onDonationError(t('donate:unexpectedError'));
      });
  };

  return (
    <PayPalButton
      amount={amount}
      shippingPreference="NO_SHIPPING"
      options={{
        clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
        intent: 'capture',
        currency: 'USD',
        // Just the paypal button, please
        disableFunding: 'card,credit,bancontact,blik,eps,giropay,ideal,mybank,sepa,p24,sofort',
      }}
      style={{
        shape: 'pill',
      }}
      onError={onDonationError}
      catchError={onDonationError}
      onApprove={order => {
        onAuthorize();
        charge(order.orderID);
      }}
    />
  );
};

DonateWithPaypal.propTypes = {
  onDonationSuccess: PropTypes.func,
  onDonationError: PropTypes.func,
  onAuthorize: PropTypes.func,
  amount: PropTypes.any,
};

export default DonateWithPaypal;
